import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export type FilterType = 'category' | 'search' | 'tag';

export interface Filter {
  label: string;
  uuid: string;
  type: FilterType;
}

@Injectable()
export class FiltersService {
  private filters = new BehaviorSubject<Filter[]>([]);
  private filterRemoved = new Subject<Filter>();

  get filtersValues(): Filter[] {
    return this.filters.value;
  }

  get filters$(): Observable<Filter[]> {
    return this.filters.asObservable();
  }

  get filterRemoved$(): Observable<Filter> {
    return this.filterRemoved.asObservable();
  }

  setFilter(filter: Filter) {
    this.filters.next([...this.filters.value, filter]);
  }

  removeTextFilter() {
    const filter = this.filters.value.find((f) => f.type === 'search');

    if (filter) {
      this.filterRemoved.next(filter);
      this.filters.next([...this.filters.value].filter((f) => f !== filter));
    }
  }

  setSearchFilter(search: string) {
    const currentFilter = this.filters.value.find((f) => f.type === 'search');
    let filters = this.filters.value;
    let filter: Filter = { uuid: search, label: search, type: 'search' };

    if (currentFilter) {
      filter = { ...filter, ...{ uuid: search, label: search } };
      filters = [...this.filters.value].filter((f) => f !== currentFilter);
    }

    this.filters.next([...filters, filter]);
  }

  removeFilter(uuid: string) {
    const filter = this.filters.value.find((f) => f.uuid === uuid);

    if (filter) {
      this.filterRemoved.next(filter);

      this.filters.next([...this.filters.value].filter((f) => f !== filter));
    }
  }

  resetAll(): void {
    this.filters = new BehaviorSubject([]);
  }

  resetFilters(): void {
    this.filters.next([]);
  }
}
