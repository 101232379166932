import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface Filter {
  name: string;
  surname: string;
  office: string;
}

@Injectable()
export class AddressBookFiltersService {
  private filters = new Subject<Filter>();

  getFilters(): Observable<Filter> {
    return this.filters.asObservable();
  }

  setFilter(filter) {
    this.filters.next({
      name: filter.name,
      surname: filter.surname,
      office: filter.office,
    });
  }
}
