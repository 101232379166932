import { Directive, ElementRef, Input } from '@angular/core';
import { StatoPratica } from 'src/app/core/models/stato-pratica.enum';

@Directive({
  selector: '[adspStateColor]',
})
export class StateColorDirective {
  mapsStatesCssClass = {
    [StatoPratica.PRESENTAZIONE]: '#A6F5F2',
    [StatoPratica.RICEZIONE]: '#FFC573',
    [StatoPratica.CONTROLLO_PAGAMENTO]: '#45E6AD',
    [StatoPratica.CONTROLLO_REQUISITI]: '#E6ECF2',
    [StatoPratica.INTEGRAZIONE_DOCUMENTAZIONE]: '#E6ECF2',
    [StatoPratica.CONTROLLO_INTEGRAZIONI]: '#44ab91',
    [StatoPratica.ACCETTAZIONE_ENTE]: '#44ab5f',
    [StatoPratica.VALIDAZIONE_ENTI_ESTERNI]: '#6fab44',
    [StatoPratica.DIVIETO_PROSECUZIONE_ATTIVITA]: '#a8ab44',
    [StatoPratica.RISOLUZIONE_DIFFORMITA]: '#ab9644',
    [StatoPratica.CONTROLLO_RISOLUZIONE_DIFFORMITA]: '#ab7644',
    [StatoPratica.REVOCA_SOSPENSIONE]: '#ab5a44',
    [StatoPratica.ISCRIZIONE_REGISTRO]: '#ac79c9',
    [StatoPratica.ARCHIVIAZIONE]: '#c97998',
    [StatoPratica.ISTANZA_NON_CONFORME]: '#C979E0',
    [StatoPratica.ACCOLTA]: '#c6eb2e',
    [StatoPratica.NON_ACCOLTA]: '#3d9d79',
    [StatoPratica.AVVIO_PROCEDIMENTO]: '#736dd9',
    [StatoPratica.PROVVEDIMENTO_EMESSO]: '#d79f3a',
    [StatoPratica.ACCOLTA_PARZIALMENTE]: '#809528',
    [StatoPratica.RIGETTATA]: '#bfc2b2',
    [StatoPratica.ISTRUTTORIA_IN_CORSO]: '#e7482c',
    [StatoPratica.RINUNCIA]: '#929292',
  };

  @Input('adspStateColor') set state(value: StatoPratica) {
    this.el.nativeElement.style.backgroundColor = this.getStatoColor(value);
  }

  constructor(private el: ElementRef) {}

  private getStatoColor(state: StatoPratica): string {
    return this.mapsStatesCssClass[state];
  }
}
