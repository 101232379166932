export enum StatoPratica {
  PRESENTAZIONE = 'PRESENTAZIONE',
  RICEZIONE = 'RICEZIONE',
  CONTROLLO_PAGAMENTO = 'CONTROLLO_PAGAMENTO',
  CONTROLLO_REQUISITI = 'CONTROLLO_REQUISITI',
  INTEGRAZIONE_DOCUMENTAZIONE = 'INTEGRAZIONE_DOCUMENTAZIONE',
  CONTROLLO_INTEGRAZIONI = 'CONTROLLO_INTEGRAZIONI',
  ACCETTAZIONE_ENTE = 'ACCETTAZIONE_ENTE',
  VALIDAZIONE_ENTI_ESTERNI = 'VALIDAZIONE_ENTI_ESTERNI',
  DIVIETO_PROSECUZIONE_ATTIVITA = 'DIVIETO_PROSECUZIONE_ATTIVITA',
  RISOLUZIONE_DIFFORMITA = 'RISOLUZIONE_DIFFORMITA',
  CONTROLLO_RISOLUZIONE_DIFFORMITA = 'CONTROLLO_RISOLUZIONE_DIFFORMITA',
  REVOCA_SOSPENSIONE = 'REVOCA_SOSPENSIONE',
  ISCRIZIONE_REGISTRO = 'ISCRIZIONE_REGISTRO',
  ARCHIVIAZIONE = 'ARCHIVIAZIONE',
  ISTANZA_NON_CONFORME = 'ISTANZA_NON_CONFORME',
  ACCOLTA = 'ACCOLTA',
  ACCOLTA_PARZIALMENTE = 'ACCOLTA_PARZIALMENTE',
  NON_ACCOLTA = 'NON_ACCOLTA',
  AVVIO_PROCEDIMENTO = 'AVVIO_PROCEDIMENTO',
  PROVVEDIMENTO_EMESSO = 'PROVVEDIMENTO_EMESSO',
  RIGETTATA = 'RIGETTATA',
  ISTRUTTORIA_IN_CORSO = 'ISTRUTTORIA_IN_CORSO',
  RINUNCIA = 'RINUNCIA',
}
