import { Directive, ElementRef, Input } from '@angular/core';
import { FasePratica } from 'src/app/core/models/fase-pratica';

@Directive({
  selector: '[adspPhaseWidth]',
})
export class PhaseWidthDirective {
  mapsPhaseValues = {
    [FasePratica.PRESENTAZIONE]: '25%',
    [FasePratica.ISTRUTTORIA]: '55%',
    [FasePratica.PROVVEDIMENTO]: '75%',
    [FasePratica.CONCLUSIONE]: '100%',
  };

  @Input('adspPhaseWidth') set phase(value: FasePratica) {
    this.el.nativeElement.style.width = this._getPercentPhase(value);
  }

  constructor(private el: ElementRef) {}

  private _getPercentPhase(phase: FasePratica): string {
    return this.mapsPhaseValues[phase];
  }
}
