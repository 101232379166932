import { ApiConfig, BackendConfig } from '@eng-ds/api-client';

declare const Adsp: any;

const apiCipPath = '/apicip/1.0';
const apiMguPath = '/apimgu/1.0';
const apiScrivaniaPath = '/apiscrivania/1.0';

const backendApi: ApiConfig[] = [
  {
    name: 'getProceedings',
    method: 'GET',
    url: apiCipPath + '/procedimenti/homePage',
  },
  {
    name: 'getCategories',
    method: 'GET',
    url: apiCipPath + '/categoriaprocedimenti',
  },
  {
    name: 'getTags',
    method: 'GET',
    url: apiCipPath + '/categoriatag',
  },
  {
    name: 'getProceedingsOrderFilter',
    method: 'GET',
    url: apiCipPath + '/procedimenti/ordinamenti',
  },
  {
    name: 'getProceedingDetail',
    method: 'GET',
    url: apiCipPath + '/procedimenti/{uuid}/{codiceLingua}',
  },
  {
    name: 'getCorrelatedProceedings',
    method: 'GET',
    url: apiCipPath + '/procedimenti/{uuid}/correlati/{codiceLingua}',
  },
  {
    name: 'downloadDocument',
    method: 'GET',
    url:
      apiCipPath + '/procedimenti/{uuidProcedimento}/documenti/{uuidDocumento}',
  },
  {
    name: 'getRubrica',
    method: 'GET',
    url: apiMguPath + '/rubrica',
  },
  {
    name: 'getTranslate',
    method: 'GET',
    url:
      Adsp.configs.get('appHost') +
      '/general_lingua/{languageCode}/{module}.json',
  },
  {
    name: 'getPratiche',
    method: 'GET',
    url: apiScrivaniaPath + '/pratica/list',
  },
  {
    name: 'getScadenze',
    method: 'GET',
    url: apiScrivaniaPath + '/agenda/next',
  },
  {
    name: 'getListRichiedenti',
    method: 'GET',
    url: apiScrivaniaPath + '/deleghe/{uuidProcedimento}/list',
  },
  {
    name: 'creaPratica',
    method: 'POST',
    url: apiScrivaniaPath + '/praticaBPM/creapratica',
  },
  {
    name: 'listVerticali',
    method: 'GET',
    url: apiMguPath + '/auth/verticali',
  },
];

export const backendConfig: Omit<BackendConfig, 'getApiConfig' | 'prepareUrl'> =
  {
    baseUrl: Adsp.configs.get('baseApiUrl'),
    api: backendApi,
  };
