import {Injectable} from '@angular/core';
import {ApiClient} from '@eng-ds/api-client';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {I18nService} from '@eng-ds/translate';
import {QuadroVerticale} from '../models/quadro-verticali.model';

@Injectable()
export class PortaleAdspService {
  constructor(
    private apiClient: ApiClient,
    private httpClient: HttpClient,
    private i18n: I18nService
  ) {}

  getListQuadriVerticali(): Observable<QuadroVerticale> {
    return this.apiClient.request<QuadroVerticale>('listVerticali');
  }
}
