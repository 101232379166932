export const IT = {
  TITLE_PAGE_HOME: 'Home',
  TITLE_PAGE_MANUTENZIONE: 'In manutenzione',
  TITLE_PAGE_RUBRICA: 'Rubrica',
  TITLE_PAGE_PROCEDIMENTI_LIST: 'Procedimenti',
  TITLE_PAGE_PROCEDIMENTI_DETAIL: 'Dettaglio procedimento',
  UTILS_ERROR: 'Errore',
  UTILS_SEARCH: 'Cerca',
  HOME_PROCEDIMENTO_LIST_TOASTR_ERROR_DETAIL_MESSAGE:
    'Non è stato possibile recuperare il procedimento richiesto, riprova più tardi',
  HOME_TITLE: 'Lo Sportello Unico Amministrativo',
  HOME_TITLE_LOGGED: 'Benvenuto nello Sportello Unico Amministrativo',
  HOME_TITLE_CF: 'CF',
  HOME_MY_PAGE: 'My Page',
  HOME_SUBTITLE: 'Lorem ipsum dolor sit amet',
  HOME_DESC:
    'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
  HOME_PROCEEDING_TITLE: 'Procedimenti',
  HOME_PROCEEDING_SUBTITLE: 'Le pratiche che puoi avviare tramite il SUA',
  HOME_PROCEEDING_BUTTON: 'Vedi tutte',
  HOME_OPERATION_TITLE: 'Operazioni',
  HOME_OPERATION_SUBTITLE: 'Cosa puoi fare con i servizi del SUA',
  HOME_OPERATION_MORE: 'Vedi procedimenti',
  HOME_ADDRESSBOOK_TITLE: 'Rubrica',
  HOME_ADDRESSBOOK_SUBTITLE:
    'Cerca i contatti del funzionario/responsabile d\'interesse',
  ORDER_ITEM_PLACEHOLDER: 'Seleziona un ordinamento',
  BREADCRUMB_HOME: 'Home',
  BREADCRUMB_PROCEEDING: 'Procedimenti',
  BREADCRUMB_ADDRESSBOOK: 'Rubrica',
  PROCEEDING_TITLE: 'Cerca procedimenti',
  PROCEEDING_RESULT: ' risultati trovati',
  PROCEEDING_ACTIVE_FILTER: 'Filtri impostati',
  PROCEEDING_ACTIVE_FILTER_NONE: 'Nessuno',
  PROCEEDING_ACTIVE_FILTER_ORDER_PLACEHOLDER: 'Seleziona un ordine',
  UTILS_AUTOCOMPLETE_TYPE_TO_SEARCH: 'Digita per ricercare',
  PROCEEDING_ORDER: 'Ordina per',
  PROCEEDING_FILTER_TITLE: 'Affina ricerca',
  PROCEEDING_CATEGORY: 'Categorie procedimenti',
  PROCEEDING_PROFILE: 'Profili',
  PROCEEDING_THEMES: 'Temi',
  PROCEEDING_LAST_UPDATE: 'Ultimo aggiornamento',
  PROCEEDING_TOPICON_null: '---',
  PROCEEDING_TOPICON_: '---',
  PROCEEDING_TOPICON_ONLINE: 'Procedimento online',
  PROCEEDING_TOPICON_PARZIALMENTE_ONLINE: 'Procedimento parzialmente online',
  PROCEEDING_TOPICON_NON_INFORMATIZZATO: 'Procedimento non informatizzato',
  PROCEEDING_TOPICON_INFORMATIZZATO: 'Procedimento informatizzato',
  PROCEEDING_COD: 'Codice',
  PROCEEDING_QUEST: 'Chi può presentare la domanda',
  PROCEEDING_INFO: 'Info principali',
  PROCEEDING_NOTE: 'Note',
  PROCEEDING_WHERE: 'Dove rivolgersi',
  PROCEEDING_NEW_BUTTON: 'Nuova richiesta',
  PROCEEDING_CAT_LABEL: 'Categoria',
  PROCEEDING_INFO_LVL: 'Liv. di informatizzazione',
  PROCEEDING_DOCUMENT: 'Documenti',
  PROCEEDING_RELATED: 'Procedimenti correlati',
  PROCEEDING_PHONE: 'Telefono',
  PROCEEDING_EMAIL: 'Email',
  PROCEEDING_PEC: 'PEC',
  PROCEEDING_MOBILE_FILTER_TITLE: 'Filtra procedimenti',
  PROCEEDING_SORT_DIGITAL_LEVEL: 'Livello informatizzazione',
  PROCEEDING_SORT_PUBLICATION_DATE: 'Data pubblicazione',
  PROCEEDING_FILTER_BUTTON: 'Filtra',
  PROCEEDING_NO_RESULT: 'Non sono stati trovati risultati',
  PROCEEDING_FAQ_RICHIEDENTE: 'Chi può presentare la domanda',
  PROCEEDING_FAQ_REQUISITI: 'Come presentare l’istanza',
  PROCEEDING_FAQ_REQUIRED_DOCUMENTS: 'Quali documenti presentare',
  PROCEEDING_FAQ_PRESENTATION: 'Termini per la presentazione della domanda',
  PROCEEDING_FAQ_COMUNICATION:
    'Come ricevere le comunicazioni - Come consultare il proprio fascicolo',
  PROCEEDING_FAQ_CONCLUSION:
    'Termini per la conclusione -  Termini per il ricorso',
  PROCEEDING_FAQ_LAW: 'Normativa',
  MANITENCE_TITLE: 'Attenzione',
  MANITENCE_TEXT: 'Si è verificato un errore si prega di riprovare!',
  MANITENCE_BUTTON: 'Torna alla Home',
  PROCEEDING_SORT_DIGITAL_LEVEL_ASC: 'Livello Informatizzazione, A-Z',
  PROCEEDING_SORT_DIGITAL_LEVEL_DESC: 'Livello Informatizzazione, Z-A',
  PROCEEDING_SORT_PUBLICATION_DATE_ASC: 'Data creazione, crescente',
  PROCEEDING_SORT_PUBLICATION_DATE_DESC: 'Data creazione, decrescente',
  SORT_SURNAME_ASC: 'Cognome, A-Z',
  SORT_SURNAME_DESC: 'Cognome, Z-A',
  ADDRESSBOOK_TITLE: 'Rubrica',
  ADDRESSBOOK_RESULT: 'contatto trovato',
  ADDRESSBOOK_RESULTS: 'contatti trovati',
  ADDRESSBOOK_FILTER_BUTTON: 'Cerca',
  ADDRESSBOOK_ORDER: 'Ordina per',
  ADDRESSBOOK_FILTER_TITLE: 'Cerca contatti',
  ADDRESSBOOK_NO_RESULT: 'Non sono stati trovati risultati',
  ADDRESSBOOK_CONTACT: 'Contatti',
  ADDRESSBOOK_PHONE: 'Telefono',
  ADDRESSBOOK_EMAIL: 'Email',
  ADDRESSBOOK_PEC: 'PEC',
  HOMEPAGE_PRATICA_DOWNLOAD_BUTTON: 'Scarica pratica',
  ADDRESSBOOK_FILTER_NAME: 'Nome',
  ADDRESSBOOK_FILTER_NAME_PLACEHOLDER: 'Cerca per nome',
  ADDRESSBOOK_FILTER_SURNAME: 'Cognome',
  ADDRESSBOOK_FILTER_SURNAME_PLACEHOLDER: 'Cerca per cognome',
  ADDRESSBOOK_FILTER_OFFICE: 'Ufficio',
  ADDRESSBOOK_FILTER_PLACEHOLDER_NAME: 'Cerca per nome',
  ADDRESSBOOK_FILTER_PLACEHOLDER_SURNAME: 'Cerca per cognome',
  ADDRESSBOOK_FILTER_PLACEHOLDER_OFFICE: 'Cerca per ufficio',
  ADDRESSBOOK_FILTER_OFFICE_PLACEHOLDER: 'Cerca ufficio',
  ADDRESSBOOK_MOBILE_FILTER_TITLE: 'Cerca contatti',
  PROCEEDING_DOC_NO_RESULT: 'Nessun documento presente',
  PRATICHE_LIST_FETCH_ERROR_TEXT:
    'Non è stato possibile recuperare le ultime pratiche, riprova più tardi',
  LIST_PRATICHE_TABLE_TITLE_LABEL: 'Procedimento',
  LIST_PRATICHE_TABLE_N_PRATICA_LABEL: 'N.Pratica',
  LIST_PRATICHE_TABLE_DATE_PROTOCOL_LABEL: 'Data protocollo',
  LIST_PRATICHE_TABLE_STATE_LABEL: 'Stato',
  LIST_PRATICHE_TABLE_PHASE_LABEL: 'Fase',
  UTILS_TABLE_EMPTY: 'Nessun elemento trovato',
  UPCOMING_DEADLINES: 'Prossime scadenze',
  LATEST_PRATICES: 'Ultime pratiche',
  PRATICE: 'Pratica',
  LIST_SCADENZE_NOT_FOUND: 'Non è prevista nessuna scadenza',
  NO_PROCEDIMENTO_CORRELATO: 'Non sono presenti procedimenti correlati',
  ISTRUTTORE: 'Istruttore',
  IMPIEGATO: 'Impiegato',
  RESPONSABILE_CONCESSIONI: 'Responsabile concessioni',
  SUPERVISORE_CONTI_FINANZIAMENTI: 'Supervisore conti e finanziamenti',
  HOMEPAGE_PRATICA_BELL_BUTTON: 'Notifiche',
  UTILS_SEE: 'Vedi',
  UTILS_VAI_ALLA_RUBRICA: 'VAI ALLA RUBRICA',
  PROCEEDING_NEW_REQUEST_MODALE_TITLE: 'Scelta Soggetto attivo',
  PROCEEDING_NEW_REQUEST_MODALE_ROLE: 'Stai operando con il seguente profilo:',
  PROCEEDING_NEW_REQUEST_MODALE_ROLE_ACTIVE: 'Ruolo attivo',
  PROCEEDING_NEW_REQUEST_MODALE_ROLE_USER: 'Utente - cittadino',
  PROCEEDING_NEW_REQUEST_MODALE_OTHER:
    'Vuoi gestire la pratica per un soggetto diverso da te?',
  UTILS_NOT: 'No',
  PROCEEDING_NEW_REQUEST_MODALE_OTHER_YES: 'Si, opero per un altro soggetto',
  PROCEEDING_NEW_REQUEST_MODALE_APPLICANT_SELECT_PLACEHOLDER:
    'Seleziona Richiedente',
  PROCEEDING_NEW_REQUEST_MODALE_APPLICANT_TEXT:
    'Scegli il richiedente per il quale gestisci la pratica',
  PROCEEDING_NEW_REQUEST_MODALE_APPLICANT_ASSOCIATI: 'Richiedenti associati',
  PROCEEDING_NEW_REQUEST_MODALE_APPLICANT_DESCRIPTION:
    'Seleziona uno dei soggetti per i quali sei stato già abilitato come delegato',
  UTILS_CONFIRM: 'Conferma',
  UTILS_TOASTR_ERROR_GENERIC_TITLE: 'Errore',
  PROCEEDING_NEW_REQUEST_ROLE_ERROR:
    'Solo il cittadino può effettuare una nuova richiesta',
  PROCEEDING_NEW_REQUEST_NO_USER:
    'Solo gli utenti autenticati possono effettuare una nuova richiesta',
  SCADENZA_TERMINE_MASSIMO_DESC: 'Descrizione della scadenza',
  SCADENZA_TERMINE_MASSIMO_TITOLO: 'Scadenza massima per la pratica',
  CIP_PROCEDIMENTO_ADD_NEW_DOCUMENT_ERROR_SCARICARE_MESSAGE:
    'Non è stato possibile scaricare il documento',

  HOMEPAGE_PORTALE_ADSP_TITLE: 'Portale AdSP',
  HOMEPAGE_PORTALE_ADSP_VERTICALI_TITLE: 'Quadri verticali',
  HOMEPAGE_PORTALE_ADSP_VERTICALI_SUB_TITLE:
    'Seleziona il quadro a cui vuoi accedere',
  SCADENZA_INTEGRAZIONE_DESC: 'Scadenza per presentazione integrazione',
  SCADENZA_INTEGRAZIONE_TITOLO: 'Scadenza per integrazione',
  SCADENZA_PREAVV_DINIEGO_DESC: 'Scadenza a seguito del preavviso diniego',
  SCADENZA_PREAVV_DINIEGO_TITOLO: 'Scadenza preavviso diniego',
};
