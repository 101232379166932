import { Injectable } from '@angular/core';
import { ApiClient } from '@eng-ds/api-client';
import { Observable, of, throwError } from 'rxjs';
import { ApiPageableResponse, ApiFiltersRequest } from '../backend';
import { CategoriaProcedimenti } from '../models/categoria-procedimenti.model';
import { CategoriaTag } from '../models/categoria-tag.moidel';
import { tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { I18nService } from '@eng-ds/translate';
import { RichiedenteItem } from '../models/richiedente.model';
import { Scadenza } from '../models/scadenza.model';

@Injectable()
export class HomeService {
  constructor(
    private apiClient: ApiClient,
    private httpClient: HttpClient,
    private i18n: I18nService
  ) {}

  getProceedings(params): Observable<any> {
    return this.apiClient.request<any>('getProceedings', null, params);
  }

  getCategories(
    params
  ): Observable<ApiPageableResponse<CategoriaProcedimenti>> {
    return this.apiClient.request<any>('getCategories', null, params);
  }

  getTags(params): Observable<ApiPageableResponse<CategoriaTag>> {
    return this.apiClient.request<any>('getTags', null, params);
  }

  getProceedingsOrderFilter(): Observable<any[]> {
    return this.apiClient.request<any>('getProceedingsOrderFilter').pipe(
      map((ordine) => {
        // tslint:disable-next-line: variable-name
        const _ordine: any[] = [];
        const keys = Object.keys(ordine);
        keys.sort();
        keys?.forEach((elm) =>
          _ordine.push({ label: this.i18n.translate(elm), value: ordine[elm] })
        );
        return _ordine;
      })
    );
  }

  getProceedingDetail(params): Observable<any> {
    return this.apiClient.request<any>('getProceedingDetail', null, null, {
      uuid: params.uuid,
      codiceLingua: params.lingua,
    });
  }

  getCorrelatedProceedings(params): Observable<any> {
    return this.apiClient.request<any>('getCorrelatedProceedings', null, null, {
      uuid: params.uuid,
      codiceLingua: params.lingua,
    });
  }

  getExportDocument(
    uuidDocumento: any,
    uuidProcedimento: any
  ): Observable<any> {
    const url = this.apiClient.getUrlByApiName('downloadDocument', {
      uuidProcedimento,
      uuidDocumento,
    });
    const bodys = { uuidDocumento, uuidProcedimento };
    return this.httpClient
      .request('GET', url, {
        body: bodys,
        params: { idLingua: '1' },
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        tap((res) => {
          const blob = res.body;
          let fileName = null;
          const contentType = res.headers.get('content-type');

          // c'è un errore nella gestione della chiamata lato server
          // perchè questo caso non dovrebbe essere un blob
          if (contentType.indexOf('application/json') > -1) {
            throwError(new Error('The server response is application/json'));
          }

          // IE/EDGE seems not returning some response header
          // tslint:disable-next-line: no-duplicate-string
          if (res.headers.get('content-disposition')) {
            const contentDisposition = res.headers.get('content-disposition');
            fileName = contentDisposition
              .substring(contentDisposition.indexOf('=') + 1)
              .replace(/['"]+/g, '');
          } else {
            fileName =
              'unnamed.' + contentType.substring(contentType.indexOf('/') + 1);
          }

          if (window.navigator.msSaveOrOpenBlob) {
            // Internet Explorer
            window.navigator.msSaveOrOpenBlob(
              new Blob([blob], { type: contentType }),
              fileName
            );
          } else {
            const el: any = document.createElement('a');
            el.href = window.URL.createObjectURL(blob);
            el.download = fileName;
            el.click();
          }
        })
      );
  }

  getAddressBook(params): Observable<any> {
    return this.apiClient.request<any>('getRubrica', null, params);
  }

  // TODO senza chiamata api solo array con traslate (rubrica)
  getAddressBookOrderFilter(): Observable<any[]> {
    // return this.apiClient.request<any>('getProceedingsOrderFilter').pipe(
    return of([
      { label: this.i18n.translate('SORT_SURNAME_ASC'), value: 'cognome,asc' },
      {
        label: this.i18n.translate('SORT_SURNAME_DESC'),
        value: 'cognome,desc',
      },
    ]);
  }

  getUltimePratiche(filters: ApiFiltersRequest) {
    return this.apiClient.request<any>(
      'getPratiche',
      null,
      filters?.toServer()
    );
  }

  getScadenze(): Observable<Scadenza[]> {
    return this.apiClient.request<Scadenza[]>('getScadenze', null, null);
  }

  getListRichiedenti(uuidProcedimento: string): Observable<any> {
    return this.apiClient.request<RichiedenteItem[]>(
      'getListRichiedenti',
      null,
      null,
      {
        uuidProcedimento,
      }
    );
  }

  creaPratica(params: any): Observable<any> {
    return this.apiClient.request<any>('creaPratica', {
      uuidProcedimento: params.uuidProcedimento,
      uuidRichiedente: params.uuidRichiedente,
      tipoRichiedente: params.tipoRichiedente,
    });
  }
}
