import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EmptyRouteComponent } from './components';
import { PhaseWidthDirective } from './directives/phase-width.directive';
import { StateColorDirective } from './directives/state-color.directive';
import { AssetUrlPipe } from './pipes';

@NgModule({
  imports: [TranslateModule.forChild()],
  exports: [
    CommonModule,
    AssetUrlPipe,
    TranslateModule,
    PhaseWidthDirective,
    StateColorDirective,
  ],
  declarations: [
    AssetUrlPipe,
    EmptyRouteComponent,
    PhaseWidthDirective,
    StateColorDirective,
  ],
})
export class SharedModule {}
